import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/watch-greenBar4'
import Works from '../sections/reg-works'
import { PageHead } from '../common'

export default () => (
  <Layout>
    <SEO
      title="Registry"
      description="Register for your china, flatware and crystal at Underwood’s. We carry the finest giftware in Jacksonville and Ponte Vedra Beach, FL. Read about how our gift registry works here." />
    <PageHead>
      <h1>Registry</h1>
      <p>It’s tradition in Jacksonville, FL for a couple to register for their china, crystal and flatware at Underwood Jewelers. We are proud of this long-standing practice and offer the finest collection of giftware in North Florida. At Underwood’s you will receive a more personalized registry experience than at other mainstream stores. We also offer free delivery in Jacksonville to all of our couples.</p>
    </PageHead>
        <Works />
    <GreenBar />
    <JoinUs />
  </Layout>
)
