import React from 'react'
import { A, Card, Wrap } from '../common/greenBar'

export default () => (
  <Wrap>
    <Card>
      <A to="/watch-repair">
        <h2>Watch Repair</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Services/icon_watchrepair.png" alt="watch repairs icon" />
        </div>
        <p>Learn More</p>
      </A>
    </Card>

    <Card css={`border-left:1px solid white;border-right:1px solid white;`}>
      <A to="/jewelry-repair">
        <h2>Jewelry Repair & Appraisals</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Services/icon_jewelryrepair.png" alt="jewelry repairs icon" />
        </div>
        <p>Learn More</p>
      </A>
    </Card>

    <Card css={`border-right:1px solid white;`}>
      <A to="/trading-selling">
        <h2>Trade in & Up</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Wedding+Anniversary/icon_tradein-and-up.png" alt="trad and sell icon" />
        </div>
        <p>Learn More</p>
      </A>
    </Card>

    <Card>
      <A to="/registry">
        <h2>Gift Registry</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Services/icon_bridalregistry.png" alt="gift registry icon" />
        </div>
        <p>Get Started</p>
      </A>
    </Card>

  </Wrap>
)
