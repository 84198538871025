import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import { Link } from 'gatsby'
import styled from 'styled-components'
import Contact from '../components/contactForm'
import Modal from '../components/modal'
import { GreenBtn, md, colors } from '../common'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Wrap>
  <Copy>
    <h1>HOW IT WORKS:</h1>
    <Num>01.</Num>
    <p>Make an appointment with a consultant at our Avondale store where most of our giftware and china is located.</p>
    <Num>02.</Num>
    <p>Register for your china, flatware, crystal and any other giftware.</p>
    <Num>03.</Num>
    <p>When your guests purchase something from your registry, the amount will be applied as a credit to your Underwood’s account. You will receive a beautiful gift letter from them giving their best wishes and stating what they purchased.</p>
    <Num>04.</Num>
    <p>Once all of the pieces are fulfilled for a certain pattern, we will order the complete set for you.</p>
    <p>This way the couple doesn’t get stuck with 7 dinner plates, 1 tea cup, 5 bread and butters and 3 red wine goblets. Thus, making them purchase the rest at a later date, which can be very expensive. If the couple does not receive the complete set they registered for, they can use their credit for anything they choose at Underwood’s.</p>
    <GreenBtn onClick={handleClick}>Schedule A Consultation</GreenBtn>
    <br />
    <div >
    <br />
      <Link css={`color:black;font-size:14px;;`} to={`/wedding-anniversary#register-bands`}>Learn more about Registering your Wedding Bands</Link>
    </div>
  </Copy>

  <Modal show={ show } toggle={ handleClick } mobile={ true }>
    <Contact />
  </Modal>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Bridal+Registry/howitworks-bg2.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: ${colors.greyDarkest};
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:40%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;
  color: ${colors.greyDarker};

  h1 {
    line-height:32px;
    color: ${colors.green};
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`

const Num = styled.p`
  color: ${colors.green};
  margin: 0;
  font-weight:700;
`
